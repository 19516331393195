import { parseUrl } from 'query-string'
import { useCallback, useEffect, useRef } from 'react'
import { getCsrfToken } from '../../utils/getCsrfToken'
import { OpenIdParams, ParsedAuthUrlQuery, POPUP_STATUS_CODES } from './types'
import {
  createOpenIdLoginUrl,
  createOpenIdLogoutUrl,
  getLoginRedirectUri,
  getLogoutRedirectUri
} from './utils/url'

const POPUP_PARAMS =
  'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=500'

export function useOpenId(params?: OpenIdParams) {
  const intervalTimer = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      clearInterval(intervalTimer.current as NodeJS.Timeout)
    }
  }, [intervalTimer])

  const login = useCallback(
    () =>
      new Promise<
        ParsedAuthUrlQuery & {
          redirectURI: string
        }
      >(async (resolve, reject) => {
        const redirectURI = getLoginRedirectUri(params?.redirectURI)

        if (typeof window === 'undefined')
          reject({
            code: POPUP_STATUS_CODES.CANNOT_OPEN
          })
        try {
          const csrf = await getCsrfToken()

          const loginUrl = createOpenIdLoginUrl({
            redirect_uri: redirectURI,
            state: csrf
          })

          const popup = window.open(loginUrl, loginUrl, POPUP_PARAMS)

          if (popup) {
            intervalTimer.current = setInterval(() => {
              try {
                const parsed = (parseUrl(popup.location.href) as unknown) as {
                  query: ParsedAuthUrlQuery
                  url: string
                }

                if (parsed.url === redirectURI) {
                  if (parsed.query.state !== csrf) {
                    reject({
                      code: POPUP_STATUS_CODES.INVALID_CSRF
                    })
                    popup.close()
                    return
                  }

                  resolve({
                    ...parsed.query,
                    redirectURI
                  })
                  clearInterval(intervalTimer.current as NodeJS.Timeout)
                  popup.close()
                  return
                }

                if (popup.closed) {
                  reject({
                    code: POPUP_STATUS_CODES.POPUP_CLOSED
                  })
                  clearInterval(intervalTimer.current as NodeJS.Timeout)
                }
              } catch (err) {}
            }, 500)
          }
        } catch (err) {
          clearInterval(intervalTimer.current as NodeJS.Timeout)
          reject({
            code: POPUP_STATUS_CODES.COMMON_ERROR,
            err
          })
        }
      }),
    [params]
  )

  const logout = useCallback(
    (tokenId?: string) => {
      const redirectURI = getLogoutRedirectUri(params?.redirectURI)

      return new Promise((resolve, reject) => {
        if (!tokenId) {
          resolve({})
          return
        }

        const logoutUrl = createOpenIdLogoutUrl({
          tokenId,
          redirect_uri: redirectURI
        })

        const popup = window.open(logoutUrl, logoutUrl, POPUP_PARAMS)

        if (popup) {
          intervalTimer.current = setInterval(() => {
            try {
              const parsed = (parseUrl(popup.location.href) as unknown) as {
                query: ParsedAuthUrlQuery
                url: string
              }

              if (parsed.url === redirectURI) {
                resolve({})
                clearInterval(intervalTimer.current as NodeJS.Timeout)

                popup.close()
                return
              }

              if (popup.closed) {
                reject({
                  code: POPUP_STATUS_CODES.POPUP_CLOSED
                })
                clearInterval(intervalTimer.current as NodeJS.Timeout)
              }
            } catch (err) {}
          }, 500)
        }
      })
    },
    [params]
  )

  return {
    login,
    logout
  }
}
