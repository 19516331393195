import { zodResolver } from '@hookform/resolvers/zod'
import { LINKS } from 'constants/links'
import { POPUP_STATUS_CODES } from 'driverama-core/auth/openid/types'
import { useOpenId } from 'driverama-core/auth/openid/useOpenId'
import { isPopupError } from 'driverama-core/auth/openid/utils/isPopupError'
import {
  LoginErrorMetadata,
  isEmployeeLoginForbiddenError
} from 'driverama-core/auth/utils/isEmployeeForbiddenError'
import { Flex } from 'driverama-core/components/Flex'
import { Select } from 'driverama-core/components/select/Select'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { toast } from 'driverama-core/components/toast/Toast'
import { localStorageClient } from 'driverama-core/utils/dom'
import { useScrollToError } from 'driverama-core/utils/hooks'
import { addSearchParams } from 'driverama-core/utils/searchParams'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { hasBpmSection } from 'sections/home/Home.utils'
import { loginEmployeeViaOpenId } from '../../api/auth/openid'
import {
  LoginFormOpenIdValues,
  useLoginFormOpenIdValidationSchema
} from './LoginFormOpenId.utils'
import { LoginButton, LoginContent } from './LoginLayout'

export function LoginFormOpenId() {
  const { t } = useTranslation(['core', 'login'])
  const { schema, errorMap } = useLoginFormOpenIdValidationSchema()
  const { login } = useOpenId()
  const router = useRouter()
  const [error, setError] = useState<LoginErrorMetadata | undefined>()

  async function handleLogout(token_id?: string) {
    await router.push(addSearchParams(LINKS.logout, { token_id }))
  }

  const form = useForm<LoginFormOpenIdValues>({
    mode: 'all',
    resolver: zodResolver(schema, { errorMap }),
    defaultValues: {
      country: 'de'
    }
  })

  const onSubmit = async (data: LoginFormOpenIdValues) => {
    if (error) {
      setError(undefined)
    }

    try {
      const loginStatus = await login()

      await loginEmployeeViaOpenId({
        authorizationCode: loginStatus.code,
        redirectUri: loginStatus.redirectURI
      })

      localStorageClient.setItem('businessCountry', data.country)

      await router.push(
        hasBpmSection(data.country) ? LINKS.home : LINKS.booking
      )
    } catch (error) {
      if (isPopupError(error)) {
        switch (error.code) {
          case POPUP_STATUS_CODES.POPUP_CLOSED:
            toast({
              type: 'error',
              content: t('login:login_popup_interrupted'),
              error
            })
            return

          case POPUP_STATUS_CODES.CANNOT_OPEN:
            toast({
              type: 'error',
              content: t('login:login_popup_cannot_open'),
              error
            })
            return

          case POPUP_STATUS_CODES.COMMON_ERROR:
            toast({
              type: 'error',
              content: t('login:login_popup_common_error'),
              error
            })
            return

          default:
            toast({
              type: 'error',
              content: t('login:login_popup_common_error'),
              error
            })
            return
        }
      }

      if (isEmployeeLoginForbiddenError(error)) {
        setError(error.response.json.metadata)
      }

      toast({ type: 'error', content: t('login:error_api'), error })
    }
  }

  useScrollToError(form.formState.errors)

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = form

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextHeader variant="h2">{t('core:appointments')}</TextHeader>
        <Spacer axis="vertical" size={31} />
        <LoginContent>
          <Select
            emptyLabel={t('login:select_country')}
            name="country"
            options={[
              {
                label: t('login:germany'),
                value: 'de'
              },
              {
                label: t('login:netherlands'),
                value: 'nl'
              }
            ]}
            disabled
          />
          <Spacer axis="vertical" size={4} />

          {error && (
            <>
              <TextBody color="warning">
                {t('login:log_in_dri_api_error', { email: error.email })}
              </TextBody>
              <Spacer size={4} axis="vertical" />
            </>
          )}

          <Flex variant="column" gap={4}>
            <LoginButton
              variant="primary"
              disabled={isSubmitting}
              type="submit"
            >
              {t('login:title')}
            </LoginButton>

            {error?.email && (
              <LoginButton
                variant="outline"
                onClick={() => handleLogout(error?.idToken)}
              >
                {t('login:log_out')}
              </LoginButton>
            )}
          </Flex>
        </LoginContent>
      </form>
    </FormProvider>
  )
}
