import { GetStaticProps } from 'next'
import { getIntlProps } from 'utils/intl'
import { LoginLayout } from '../sections/login/LoginLayout'
import { LoginForm } from '../sections/login/LoginForm'

import { SMainLayout } from 'components/layout/Layout'
import { Header } from 'components/header/Header'
import { LoginFormOpenId } from '../sections/login/LoginFormOpenId'

export default function Page() {
  return (
    <SMainLayout>
      <Header />
      <LoginLayout>
        {driverama.flags.IS_KEYCLOAK_ENABLED ? (
          <LoginFormOpenId />
        ) : (
          <LoginForm />
        )}
      </LoginLayout>
    </SMainLayout>
  )
}

export const getStaticProps: GetStaticProps = async ctx => ({
  props: await getIntlProps(ctx, ['login'])
})
