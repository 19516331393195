import { URLS } from 'constants/api'
import { paths } from 'driverama-core/api/driverama/generated/employees'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { login } from 'driverama-core/utils/auth'
import {
  apiFetcher,
  createAcceptHeaderWithVersion
} from 'driverama-core/utils/fetcher'

export const loginEmployeeViaOpenId = async (body: {
  authorizationCode: string
  redirectUri: string
}) => {
  const response = await apiFetcher<
    paths['/employees/login']['post']['responses']['200']['content']['application/com.driverama-v2+json']
  >(URLS.employeesLogin, {
    method: HTTPMethod.POST,
    headers: {
      Accept: createAcceptHeaderWithVersion(2)
    },
    body
  })

  if (!response.json) return

  await login({
    expires: response.json?.expiresInS,
    refresh_token: response.json?.refreshToken,
    refresh_expires: response.json?.refreshExpiresInS,
    token_id: response.json.idToken
  })
}
