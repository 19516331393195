import styled from '@emotion/styled'
import { size, spaceY } from 'driverama-core/styles/spacing'
import { Button } from 'driverama-core/components/button/Button'

export const LoginLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: ${size(2)} ${size(5)};
  box-sizing: border-box;
`

export const LoginContent = styled.div`
  max-width: ${size(80)};
  width: 100%;
  margin: 0 auto;
  ${spaceY(4)}
`

export const LoginButton = styled(Button)`
  width: 100%;
`
