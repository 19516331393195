import { useTranslation } from 'react-i18next'
import { useDefaultZodErrorMap } from 'utils/validations'
import { object, string } from 'zod'
import { infer as zodInfer } from 'zod/lib/types'

export function useLoginFormOpenIdValidationSchema() {
  const { t } = useTranslation(['core', 'login'])

  const schema = object({
    country: string().nonempty(t('core:validation_required'))
  })

  const defaultErrorMap = useDefaultZodErrorMap()
  return { schema, errorMap: defaultErrorMap }
}

export type LoginFormOpenIdValues = zodInfer<
  ReturnType<typeof useLoginFormOpenIdValidationSchema>['schema']
>
