interface OpenIdLoginUrlParams {
  redirect_uri: string
  state: string
}

interface OpenIdLogoutUrlParams {
  tokenId: string
  redirect_uri: string
}

export function createOpenIdLoginUrl(params: OpenIdLoginUrlParams) {
  const requiredParams = new URLSearchParams({
    client_id: 'auth-code-flow-client',
    response_type: 'code',
    scope: 'openid offline_access',
    kc_idp_hint: 'azuread',
    ...params
  })

  return `${
    process.env.NEXT_PUBLIC_AUTH_URL
  }/realms/driverama/protocol/openid-connect/auth/?${requiredParams.toString()}`
}

export function createOpenIdLogoutUrl(params: OpenIdLogoutUrlParams) {
  const requiredParams = new URLSearchParams({
    id_token_hint: params.tokenId,
    post_logout_redirect_uri: params.redirect_uri
  })

  return `${
    process.env.NEXT_PUBLIC_AUTH_URL
  }/realms/driverama/protocol/openid-connect/logout?${requiredParams.toString()}`
}

function getRedirectUri(redirectURI?: string, subPath?: string) {
  if (redirectURI) {
    return redirectURI
  }

  if (typeof window !== 'undefined') {
    return `${window.location.origin}/api/auth/openid/${subPath}`
  }

  return ''
}

export function getLoginRedirectUri(redirectURI?: string) {
  return getRedirectUri(redirectURI, 'logged')
}

export function getLogoutRedirectUri(redirectURI?: string) {
  return getRedirectUri(redirectURI, 'logout')
}
