export enum POPUP_STATUS_CODES {
  CANNOT_OPEN = 'CANNOT_OPEN',
  POPUP_CLOSED = 'POPUP_CLOSED',
  COMMON_ERROR = 'COMMON_ERROR',
  INVALID_CODE = 'INVALID_CODE',
  INVALID_CSRF = 'INVALID_CSRF'
}

export interface ParsedAuthUrlQuery {
  code: string
  state: string
}

export interface OpenIdParams {
  redirectURI?: string
  onClose?: () => void
  onSuccess?: (props: ParsedAuthUrlQuery, redirectUri: string) => Promise<void>
}

export interface EmployeeLoginOpenIdBody {
  authorizationCode: string
  redirectUri: string
}

export interface PopupError {
  code: POPUP_STATUS_CODES
  err?: string
}
