export type LoginErrorMetadata = {
  email: string
  idToken: string
}

type EmployeeLoginForbiddenError = {
  path: string
  statusCode: 403
  response: {
    status: 403
    json: {
      code: '101104'
      message: string
      metadata: LoginErrorMetadata
    }
  }
}

export function isEmployeeLoginForbiddenError(
  error: unknown
): error is EmployeeLoginForbiddenError {
  return (error as EmployeeLoginForbiddenError).response.json.code === '101104'
}
